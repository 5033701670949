.gantt-table-group {
  @apply border-b-0 border-t border-t-stroke #{!important};

  & > .gantt-table-group-title {
    @apply grow #{!important};

    & > .expand-icon {
      @apply my-auto max-h-max #{!important};
    }
  }
}

.gantt-table-column {
  & > .gantt-expand-icon {
    @apply hidden #{!important};
  }
}

.gantt-table-item {
  @apply mt-0 border-none #{!important};
}
