context-menu-content {
  animation: animation-context-menu-content 0.03s ease-in forwards !important;
}

@keyframes animation-context-menu-content {
  from {
    transform: scale(0.95);
    opacity: 0.6;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
