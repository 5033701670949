@use '../../styles/variables';

.gantt-links-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  svg {
    overflow: visible;
    z-index: 2;
    position: absolute;
  }
}

.link-dragging-container {
  width: 100%;
  height: 1px;
  overflow: visible !important;
  position: absolute;
  left: 0;
  z-index: 2000;

  .link-dragging-line {
    stroke: variables.$gantt-link-line-color;
    stroke-width: 2px;
  }
}
