nz-select {
  & > nz-select-top-control {
    @apply rounded-md #{!important};
  }

  &[nzsize="large"] {
    @apply h-[46px] #{!important};

    & > nz-select-top-control {
      @apply h-[46px] leading-[42px] #{!important};

      & nz-select-item {
        @apply leading-[42px] #{!important};
       
      }

      & input {
        @apply h-[46px] #{!important};
      }

      & nz-select-placeholder {
        @apply leading-[42px] #{!important};
      }
    }
  }

  &[nzsize="small"] {
    @apply h-6 #{!important};

    & > nz-select-top-control {
      @apply h-6 #{!important};
    }
  }
}

nz-option-container {
  @apply rounded-md shadow-md #{!important};

  & nz-option-item {
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 8px; // default 0 11px;
}

.ant-select-multiple
.ant-select-selection-item {
  @apply border-none bg-transparent font-bold gap-2 text-text-primary italic #{!important};
}
