input[nz-input] {
  @apply h-8 rounded-md #{!important};

  &[nzsize="large"] {
    @apply h-[46px] #{!important};
  }

  &[nzsize="small"] {
    @apply h-6 #{!important};
  }
}

nz-input-group {
  @apply rounded-md #{!important};

  & > input[nz-input] {
    @apply h-8 #{!important};

    &[nzsize="large"] {
      @apply h-[36px] #{!important};
    }
  }
}

nz-input-number {
  @apply h-8 rounded-md #{!important};

  & > .ant-input-number-input-wrap {
    @apply h-full #{!important};

    & > input {
      @apply h-full #{!important};
    }
  }

  &[nzsize="large"] {
    @apply h-[46px] #{!important};
  }

  &[nzsize="small"] {
    @apply h-6 #{!important};
  }
}

textarea[nz-input] {
  @apply rounded-md #{!important};
}

nz-input-number-group {
  @apply overflow-hidden rounded-md #{!important};

  & > nz-input-number {
    &[nzsize="large"] {
      @apply h-[44px] #{!important};
    }

    &[nzsize="small"] {
      @apply h-6 #{!important};
    }
  }
}
