.gantt-icon {
    font-size: 1rem;
    line-height: 0;

    svg {
        width: 1em;
        height: 1em;
        fill: currentColor;
        vertical-align: -0.125em;
        background-repeat: no-repeat;
    }

}
