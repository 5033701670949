@use '../../styles/variables.scss';

.gantt-baseline {
  position: absolute;
  z-index: 2;
  .baseline-content {
    height: 8px;
    border-radius: 2px;
    background-color: variables.$gantt-baseline-background-color;
  }
}
