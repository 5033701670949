ngx-gantt-bar,
gantt-bar {
  @apply z-20 #{!important};

  // &.gantt-bar-draggable-drag,
  // &.gantt-bar-active {
  //   @apply transition-none #{!important};
  // }

  & > .gantt-bar-content {
    & > .gantt-bar-content-progress {
      @apply transition-all duration-500 #{!important};
    }
  }
}
