@use '../../styles/variables';

.gantt-drag-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: none;
  overflow: hidden;
}

.gantt-drag-mask {
  position: absolute;
  z-index: 1;
  height: 100%;
  display: none;
  background: rgba($color: variables.$gantt-item-drag-mask-color, $alpha: 0.05);

  .date-range {
    width: 100%;
    min-width: 120px;
    top: -23px;
    background: variables.$gantt-item-drag-mask-color;
    line-height: 23px;
    border-radius: 4px;
    color: #fff;
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
  }
}

.gantt-link-drag-container {
  width: 100%;
  height: 1px;
  overflow: visible !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;

  .link-dragging-line {
    stroke: variables.$gantt-link-dragging-line-color;
    stroke-width: 2px;
  }
}
