gridster {
  .gridster-column,
  .gridster-row {
    @apply border-transparent #{!important};
  }
}

gridster-item {
  @apply bg-transparent #{!important};

  &.gridster-item-moving {
    @apply shadow-none #{!important};
  }

  &.gridster-item-resizing {
    @apply shadow-none #{!important};
  }

  & > .gridster-item-resizable-handler {
    @apply border-transparent #{!important};
  }
}

gridster-preview {
  @apply rounded-2xl bg-spot-blasphemous-200/25 #{!important};
}
