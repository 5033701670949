nz-date-picker {
  @apply overflow-hidden rounded-md #{!important};
}

.ant-picker-large {
  padding: 9px 11px; // default 6.5px 11px
}

.ant-picker-dropdown {
  @apply top-1 overflow-hidden rounded-md shadow #{!important};

  & .ant-picker-cell {
    & > .ant-picker-cell-inner {
      @apply rounded #{!important};

      &::before {
        @apply rounded #{!important};
      }
    }
  }
}
