// basic
$gantt-color: #333 !default;
$gantt-primary-color: #6698ff !default;
$gantt-header-height: 44px !default;
$gantt-border-color: #eee !default;
$gantt-bg-color: #fff !default;
$gantt-side-shadow: 12px 0 16px -10px rgba(0, 0, 0, 0.15) !default;
$gantt-container-background-color: #fafafa !default;
$gantt-group-background-color: rgba(
  $color: #f3f3f3,
  $alpha: 0.5
) !default;
$gantt-table-td-padding: 0 15px !default;

// calendar
$gantt-date-primary-color: #888 !default;
$gantt-date-primary-font-size: 14px !default;
$gantt-date-primary-border: #ddd !default;
$gantt-date-secondary-color: #333 !default;
$gantt-date-secondary-font-size: 14px !default;
$gantt-date-secondary-weekend-color: #aaa !default;
$gantt-date-week-backdrop-bg: rgba(
  $color: #f3f3f3,
  $alpha: 0.5
) !default;
$gantt-date-today-color: #ff9f73 !default;
$gantt-date-today-text-color: #fff !default;

// bar
$gantt-bar-bg: #fff !default;
$gantt-bar-layer-bg: #fff !default;
$gantt-bar-handle-color: #cacaca !default;
$gantt-bar-handle-height: 12px !default;
$gantt-bar-link-handle-point: $gantt-primary-color !default;
$gantt-bar-background-color: $gantt-primary-color !default;

// drag
$gantt-item-drag-mask-color: $gantt-primary-color !default;
$gantt-link-dragging-line-color: $gantt-primary-color !default;

// link
$gantt-link-line-color: $gantt-primary-color !default;

// table
$gantt-table-header-drag-line-width: 3px !default;
$gantt-table-header-drag-line-color: $gantt-primary-color !default;
$gantt-table-items-drop-background-color: #f5f5f5 !default;

// baseline
$gantt-baseline-background-color: #cacaca !default;

// toolbar
$gantt-toolbar-view-active-color: $gantt-primary-color !default;

// loader
$gantt-loader-loading-color: $gantt-primary-color !default;

// footer
$gantt-footer-height: 44px !default;

// @deprecated, please use global config `GANTT_GLOBAL_CONFIG` set custom height
$gantt-item-height: none;
$gantt-group-height: none;
