/* You can add global styles to this file, and also import other style files */
@use "./styles/partials";
@use "@angular/cdk/overlay-prebuilt.css";
@use "quill/dist/quill.snow.css";
@use "@worktile/gantt/styles/index.scss";
@use "@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss";
@use "@ctrl/ngx-emoji-mart/picker";
@import '../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';
@tailwind base;
@layer base {
  html,
  body {
    @apply font-sans;
  }
}
@tailwind components;
@tailwind utilities;

@layer base {
  ul, ol {
    list-style: revert;
    padding: revert;
    margin: revert;
  }
}

// light theme
:root {
  --background: theme("colors.white");
}

// dark theme
.dark {
  --background: theme("colors.zinc.950");
}

html {
  @apply overflow-hidden;
}

body {
  @apply overflow-auto bg-white;
  min-width: 100vw;
  min-height: 100vh;
}

router-outlet {
  display: none;
}

context-menu-content {
  @apply rounded-md border-none bg-white shadow-md transition-colors #{!important};

  & > .ngx-context-menu-item:not(hr) {
    @apply min-h-8 select-none bg-transparent font-nunito-sans text-sm leading-[150%] text-text-primary transition-colors hover:bg-spot-blasphemous-100 disabled:text-text-primary/50 #{!important};

    &.ngx-contextmenu-item--passive {
      @apply hover:bg-transparent #{!important};
    }
  }
}

/* scrollbar */

/* headings */
h1 {
  @apply text-4xl font-bold leading-[120%] text-text-primary;
}

h2 {
  @apply text-[1.75rem] font-bold leading-[120%] text-text-primary;
}

h3 {
  @apply text-2xl font-bold leading-[120%] text-text-primary;
}

h4 {
  @apply text-xl font-bold leading-[120%] text-text-primary;
}

h5 {
  @apply text-lg font-bold leading-[120%] text-text-primary;
}

h6 {
  @apply text-base font-bold leading-[120%] text-text-primary;
}

/* anchor */
a {
  @apply font-nunito-sans font-semibold text-primary;
}

p {
  @apply text-base;
}

/* cdk drag drop */
.cdk-drag-placeholder {
  opacity: 0;
}

app-backlog-task,
app-board-task {
  &.cdk-drag-placeholder {
    @apply border border-stroke bg-gray-50 shadow-none;
    opacity: 1;

    & > * {
      opacity: 0;
    }
  }
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  @apply shadow-md;
}

app-backlog-task.cdk-drag-preview {
  @apply rounded;
}

.cdk-drop-list-dragging app-board-column:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

ngx-guided-tour {
  button:nth-child(2) {
    order: 2;
  }

  button:nth-child(3) {
    order: 1;
  }
  // .guided-tour-spotlight-overlay {
  //   max-height: 200px !important;
  // }
  .back-button {
    @apply rounded-lg text-sm bg-transparent px-5 py-3 font-nunito-sans font-semibold border-spot-blasphemous-900 leading-[150%] text-spot-blasphemous-900 transition-colors hover:bg-transparent #{!important};
  }
  .next-button {
    @apply rounded-lg text-sm bg-primary px-5 py-3 font-nunito-sans font-semibold leading-[150%] text-white transition-colors hover:bg-spot-blasphemous-800 active:bg-spot-blasphemous-900 disabled:bg-primary/75 #{!important};
  }
  .skip-button {
    @apply rounded-lg px-5 py-3 font-nunito-sans font-semibold text-sm leading-[150%] text-text-tertiary transition-colors hover:bg-gray-500/10 active:bg-gray-500/25 #{!important};
  }
  .tour-buttons {
    @apply flex items-center justify-between #{!important}
  }
  .tour-block {
    @apply rounded-lg #{!important};
  }
}

/* Quill Editor */
quill-view-html {
  .ql-editor li {
    list-style: unset !important;
    padding-left: 0 !important;
  }
}
quill-editor {
  display: block;
  @apply rounded;
  
  .ql-container {
    @apply rounded font-nunito-sans leading-[150%];
  }

  .ql-toolbar {
    @apply bg-white;
    @apply rounded-t;

    & + .ql-container {
      @apply rounded-t-none;
    }
  }

  .ql-editor {
    @apply min-h-32 bg-white;
  }
}

quill-view-html {
  .ql-editor {
    @apply m-0 p-0 font-nunito-sans font-medium leading-[150%] text-text-tertiary;

    &,
    p {
      @apply text-sm font-semibold text-text-secondary;
    }
  }

  .ql-container {
    @apply h-full;
  }

  &.preview-align-middle {
    .ql-editor {
      @apply flex; //Add justify-center in case we want the placeholder to be in the middle
    }
  }

  &.editable {
    .ql-editor {
      @apply cursor-pointer; //Add justify-center in case we want the placeholder to be in the middle
    }
  }

  /* fixing the issue with empty <p> which should contain <br> tag inside as in the editor */
  p:empty:before {
    content: "\A";
    display: block;
  }
}

.ql-editor,
.ql-container {
  @apply flex h-auto flex-1 flex-col;
}

.quill-editor-mention {
  @apply inline-flex;
  @apply items-center;
  @apply justify-center;
  @apply me-2;
  @apply px-2.5;
  @apply py-0.5;
  @apply rounded-full;
  @apply text-xs;
  @apply font-semibold;
  @apply bg-blue-100;
  @apply hover:bg-blue-200;
  @apply text-blue-700;
  @apply dark:bg-gray-700;
  @apply dark:text-blue-400;
  @apply border;
  @apply border-blue-400;
  @apply cursor-pointer;
}

#quill-mention-list {
  @apply pl-0;
}

.quill-editor-mention-list-item {
  @apply bg-white;
  @apply px-3 py-2;

  @apply list-none;

  &.selected {
    @apply bg-gray-100;
    box-shadow: inset 2px 0px 0px #2353a0;
  }
}

.quill-editor-mention-list-item {
  @apply bg-white transition-all;

  &.selected {
    @apply bg-primary/10 shadow-none;
  }
}

.quill-editor-mention {
  @apply m-0 rounded border-none bg-transparent px-1 font-sans font-bold text-primary transition-colors hover:bg-primary/25;
}

.quill-upload-video {
  @apply max-w-[75%];
}

/* Overlay expand */
.cdk-task-overlay-pane--expandable {
  will-change: width;
  @apply transition-[width] will-change-[width];

  .cdk-dialog-container {
    @apply flex justify-end;
  }
}

/* Ant Image preview */
.ant-image-preview-img-wrapper {
  @apply flex items-center justify-center;
}

/* tooltip */
.ant-tooltip {
  & > .ant-tooltip-content {
    & > .ant-tooltip-arrow {
      & > .ant-tooltip-arrow-content::before {
        background: #e5e7f0; //Didn't work with bg-spot-blasphemous-200
      }
    }

    & > .ant-tooltip-inner {
      @apply rounded bg-spot-blasphemous-200 text-text-primary;
    }
  }
}

/* tooast */
.ant-message-notice-content {
}

/* Avatar */
.ant-avatar-string {
  @apply -translate-x-1/2;
}

/* clip path */
.cp-top {
  clip-path: inset(-16px 0 0 0);
}

.cp-right {
  clip-path: inset(0 -16px 0 0);
}

.cp-bottom {
  clip-path: inset(0 0 -16px 0);
}

.cp-left {
  clip-path: inset(0 0 0 -16px);
}

button:disabled {
  opacity: 0.7;
}

@property --gradient-angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

:root {
  --clr-1: #3a86ff;
  --clr-2: #766ef6;
  --clr-3: #9c60f0;
  --clr-4: #ff338b;
  --clr-5: #ffbe0b;
}

@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }
  100% {
    --gradient-angle: 360deg;
  }
}

.border-glow {
  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: -1px;
    z-index: -1;
    background: conic-gradient(
      from var(--gradient-angle),
      var(--clr-3) 7%,
      var(--clr-4) 21%,
      var(--clr-5) 41%,
      var(--clr-4) 65%,
      var(--clr-3) 94%
    );
    border-radius: inherit;
    animation: rotation 10s linear infinite;
  }

  &::after {
    filter: blur(0.1rem);
  }
}

// ? added to prevent some issues with the roadmap overlay
.cdk-overlay-container {
  z-index: 1100;
}

label {
  @apply font-nunito-sans text-sm font-semibold text-text-primary;
}
