button[nz-button] {
  @apply rounded-md #{!important};
}

nz-button-group {
  & > button[nz-button] {
    @apply flex items-center gap-2 rounded-none #{!important};

    &:first-child {
      @apply rounded-l-md #{!important};
    }

    &:last-child {
      @apply rounded-r-md #{!important};
    }
  }

  & > button[nz-button][nz-dropdown] {
    @apply flex items-center justify-center #{!important};
  }
}
