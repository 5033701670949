gantt-table-body {
  & .gantt-table-group,
  & .gantt-table-body-container,
  & .gantt-table-column {
    @apply bg-spot-heading #{!important};
  }
}

gantt-table-body,
gantt-table-header {
  @apply shadow-none #{!important};
}

gantt-table-header {
  @apply border-b border-r-0 border-spot-blasphemous-300 #{!important};
}

.gantt-table-body .gantt-table-item + .gantt-table-item {
  @apply mt-4;
}

.gantt-side {
  @apply shadow-none #{!important};
}

.gantt-side {
  @apply bg-spot-heading #{!important};
}

.gantt-main-groups {
  @apply bg-white #{!important};

  & .gantt-group {
    @apply bg-white #{!important};
  }
}

.table-resize-handle,
.column-resize-handle {
  // @apply hidden;
}

.gantt-table-group-title {
  @apply flex;
}
