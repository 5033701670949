label[nz-checkbox] {
  & > .ant-checkbox {
    @apply top-[.25em] #{!important};

    & > .ant-checkbox-input {
      //   @apply rounded-md #{!important};
    }

    & > .ant-checkbox-inner {
      @apply h-5 w-5 rounded #{!important};
    }
  }
}
